import https from 'https'
import { useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

// Types
import { ElasticFieldsInterface } from '@/types/search/elasticTypes'
import { CheckedFacetsInterface } from '@/types/search/searchTypes'

// Composables
import useAnalytics from '@/composables/analytics/useAnalytics'
import useAppSearch from '@/composables/search/useAppSearch'

// Store
import { useCompareProductsStore } from '@/store/compare-products'
import { useUserStore } from '@/store/user'
import { useSearchStore } from '~/store/search'

// Common
import { SEARCH_TYPES } from '@/common/static/search'
// import { centsToPrice } from '@/common/utils/numbers'

export default function () {
  const context = useContext()
  const analytics = useAnalytics()
  const searchStore = useSearchStore()
  const { getResults: results, page } = storeToRefs(searchStore)
  const compareStore = useCompareProductsStore()
  const { comparedProducts } = storeToRefs(compareStore)
  const userStore = useUserStore()
  const { getElasticBase, getAxiosHeaders } = useAppSearch()

  const getSearchInteractionParameters = (facetname: string = '', facetvalue: string = '') => {
    return {
      event: 'search_interaction',
      facet_name: facetname.length > 0 ? facetname : '',
      facet_value: facetvalue.length > 0 ? facetvalue : '',
      search_term: searchStore.searchInput,
      search_results: results.value?.meta?.page?.total_results,
      search_tab: searchStore.tabs.selectedTab,
      sort_value: searchStore.page.sortField,
    }
  }

  /*
    Parameters for the searchResults GTM event
  */
  const trackSearchResults = (options: {
    searchTerm: string
    searchType: string
    searchResults: number
    searchResponseTime?: number
    searchFilters?: Array<CheckedFacetsInterface>
  }) => {
    const searchResultsParams = {
      ga4: {
        event: 'search',
        category: 'search',
        search_term: options.searchTerm,
        search_type: options.searchType,
        search_results: options.searchResults,
        search_filters: options.searchFilters || '',
        search_response_time: options.searchResponseTime || '',
        ...analytics.getCustomDimensions().ga4,
      },
    }

    context.$gtm.push(searchResultsParams.ga4)

    // send another event for GA4 - view_item_list
    // to utilize the full power of GA4’s monetization report
    const viewItemListParams = {
      event: 'view_item_list',
      item_list_id: 'PLP',
      item_list_name: 'Product Listing Page',
      ecommerce: {
        items: results.value.results?.map((result: ElasticFieldsInterface) => {
          return {
            item_id: result.productnumber?.raw,
            item_name: result.name?.raw,
          }
        }),
      },
    }

    analytics.pushAndClearEcommerceObject(viewItemListParams)
  }

  /*
    Parameters for the e_productClick GTM event
  */
  const trackProductClick = (fields: ElasticFieldsInterface) => {
    // Get product info from merchant center for curreny & value product
    const value = 0
    const currency = userStore.selectedCountry

    // try {
    //   const { data } = await context.$ct.products.getProductsInfoByProductIdsInKey([fields.productnumber.raw])
    //   const prices = data.results.length > 0 ? data.results[0].variants[0].prices : []
    //   const price = prices.filter((el: ProductPriceInterface) => el.country === userStore.selectedCountry)
    //   value = centsToPrice(price[0].value.centAmount, price[0].value.fractionDigits)
    // } catch (e) {
    //   console.error(e)
    // }

    // check if this is a redirect or a real click
    const productClickPosition =
      fields.directproductnumber === undefined ? searchStore.getProductListPosition(fields.productnumber.raw) : 1
    const searchType =
      fields.directproductnumber === undefined ? SEARCH_TYPES.PRODUCT + ' click' : 'Direct productnumber search'
    const searchTerm = searchStore.searchInput

    const productClickParams = {
      ga4_view_item: {
        event: 'view_item',
        ecommerce: {
          currency,
          value,
          items: [
            {
              item_id: fields.productnumber.raw,
              item_name: fields?.name?.raw,
              index: productClickPosition
                ? page.value.resultsPerPage * (page.value.currentPage - 1) + productClickPosition
                : undefined,
            },
          ],
        },
        search_term: searchTerm,
        search_type: searchType,
        ...analytics.getCustomDimensions().ga4,
      },
      ga4_select_item: {
        event: 'select_item',
        item_list_id: 'PLP',
        item_list_name: 'Product Listing Page',
        ecommerce: {
          items: [
            {
              item_id: fields.productnumber.raw,
              item_name: fields?.name?.raw,
              index: productClickPosition
                ? page.value.resultsPerPage * (page.value.currentPage - 1) + productClickPosition
                : undefined,
            },
          ],
        },
        ...analytics.getCustomDimensions().ga4,
      },
    }

    analytics.pushAndClearEcommerceObject(productClickParams.ga4_select_item)
    analytics.pushAndClearEcommerceObject(productClickParams.ga4_view_item)
  }

  /*
    Parameters for the e_productClick GTM event
  */
  const trackResourceClick = (fields: ElasticFieldsInterface) => {
    const title = fields.headings?.raw[1] ?? fields.title?.raw

    context.$gtm.push({
      event: 'select_content',
      content_type: 'Resources',
      content_id: title,
      ...analytics.getCustomDimensions().ga4,
    })
  }

  /*
    Parameters for sending a filter event
  */
  const trackFilteredSearch = (filterApplied: string, filterValue: string) => {
    context.$gtm.push({
      ...getSearchInteractionParameters(filterApplied, filterValue),
      ...analytics.getCustomDimensions().ga4,
    })
  }

  /*
    Parameters for the direct productnumber search event
  */
  const trackSearchSorted = () => {
    context.$gtm.push({
      ...getSearchInteractionParameters(),
      ...analytics.getCustomDimensions().ga4,
    })
  }

  /*
    Parameters for the direct productnumber search event
    - only available in GA4
  */
  const trackSearchItemtypeTab = () => {
    const searchItemtypeTabParams = {
      ...getSearchInteractionParameters(),
      ...analytics.getCustomDimensions().ga4,
    }
    context.$gtm.push(searchItemtypeTabParams)
  }

  /*
    Parameters for the direct productnumber search event
  */
  const trackSearchCompare = () => {
    const compareParams = {
      ga4: {
        event: 'compare_products',
        compare_products: comparedProducts.value.map((field) => field.productTitle ?? field.error),
        products_number: comparedProducts.value.length,
        ...analytics.getCustomDimensions().ga4,
      },
    }

    context.$gtm.push(compareParams.ga4)
  }

  /*
    Parameters for typeahead search event
  */
  const trackTypeaheadSuggestionClick = (typeaheadTitle: string, typeaheadStack: string) => {
    // GA4
    const ga4Params = {
      event: 'typeahead_suggestion_click',
      search_term: searchStore.searchInput,
      typeahead_stack: typeaheadStack,
      typeahead_title: typeaheadTitle,
      ...analytics.getCustomDimensions().ga4,
    }
    context.$gtm.push(ga4Params)
  }

  /*
    Detect a click event on a product
    send a analytics request to Elasticsearch
  */
  const trackElasticsearchClickEvent = async (documentId: number | string) => {
    // don't record anything is nothing is searched yet
    if (searchStore.searchInput === '') {
      return
    }

    // define click analytics body
    const clickObject = {
      query: searchStore.searchInput,
      request_id: results.value.meta?.request_id,
      document_id: documentId,
    }

    // perform request to the click api
    // The click api requires the regular search key so we can send the request directly
    // We only track clicks on products
    try {
      await context.$axios.post(getElasticBase('product') + '/click/', clickObject, {
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
        headers: getAxiosHeaders(),
      })
    } catch (error: any) {
      // In case of a failure, log the error
      const logName = { type: 'ElasticSearchAxiosError' }
      const newError = { ...error, ...logName }
      context.$logService.sendError(newError)
    }
  }

  return {
    trackSearchResults,
    trackProductClick,
    trackResourceClick,
    trackFilteredSearch,
    trackSearchSorted,
    trackSearchItemtypeTab,
    trackSearchCompare,
    trackTypeaheadSuggestionClick,
    trackElasticsearchClickEvent,
  }
}
