import { valutaMapping } from '@/common/mapping/mapping'

export const formatNumber = (number: string | number, numOfFractionDigits: number, locale: string = 'en-US') => {
  return number.toLocaleString(locale, {
    minimumFractionDigits: numOfFractionDigits,
    maximumFractionDigits: numOfFractionDigits,
  })
}

export const centsToPrice = (number: number, fractionDigits: number) => {
  return number / Math.pow(10, fractionDigits)
}

export const formatPrice = (
  centAmount: number,
  currency: keyof typeof valutaMapping,
  fractionDigits: number,
  toDecimalPoints?: number
) => {
  return (
    valutaMapping[currency] + formatNumber(centsToPrice(centAmount, fractionDigits), toDecimalPoints ?? fractionDigits)
  )
}

/**
 * Format price based on the cent amount
 * * When the cent amount is negative returns format needed to display for Returned Item Total Price
 * * When the cent amount is positive returns regular format of total price
 * @param centAmount  number
 * @param currency string "USD" | "EUR" | "GBP" | "JPY" | "CNY"
 * @param fractionDigits number
 * @returns formatted price with valuta
 */
export const formatReturnedTotalPrice = (
  centAmount: number,
  currency: keyof typeof valutaMapping,
  fractionDigits: number
) => {
  return centAmount < 0
    ? `-(${formatPrice(centAmount * -1, currency, fractionDigits)})`
    : formatPrice(centAmount, currency, fractionDigits)
}
