import { extractProductIdFromSku } from '~/common/utils/product/product'
import { useCountry } from '../country/useCountry'
import { useCMSGlobalSettingsStore } from '~/store'

export function isProductExcludedFromSale(product?: { sku: string; price?: any }) {
  const { isUS, isJapan } = useCountry()
  const { getAttributeByCountry } = useCMSGlobalSettingsStore()
  const excludedProductsUS = getAttributeByCountry('excludedProducts', 'US').value
  const excludedProductsJP = getAttributeByCountry('excludedProducts', 'JP').value

  const productId = extractProductIdFromSku(product?.sku ?? '')

  return (
    (isUS.value && productId && excludedProductsUS.includes(productId)) ||
    (isJapan.value && productId && excludedProductsJP.includes(productId)) ||
    !product?.price?.centAmount
  )
}
