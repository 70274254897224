import { Context } from '@nuxt/types'
import { MiddlewareFunction } from '~/types/common/commonTypes'

type MiddlewareOptions = {
  routes: string[]
  middleware: MiddlewareFunction | MiddlewareFunction[]
}

export function withExceptionRoutes({ routes = [], middleware }: MiddlewareOptions) {
  return (context: Context) => {
    let allowedRoute = false

    routes.forEach((route) => {
      if (route === context.route.path) {
        allowedRoute = true
      }
    })

    if (!allowedRoute) {
      if (Array.isArray(middleware)) {
        middleware.forEach((m) => m(context))
      } else {
        middleware(context)
      }
    }
  }
}
