import { defineStore } from 'pinia'
import { COMPONENTS_IN_NAVIGATION } from '~/common/utils/product/constants'
import { PDPTemplate, parseTemplateData } from '~/modules/contentstack/pdpTemplateParser'
import {
  FetchPdpPageByProductIdQuery,
  FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery,
  PdpPage,
} from '~/modules/contentstack/types'

export type ComponentsInNavigation = (typeof COMPONENTS_IN_NAVIGATION)[keyof typeof COMPONENTS_IN_NAVIGATION]

type SelectedApplication = {
  code: string | undefined
  group: string | undefined
  protocolId: string | undefined
}

export const usePdpStore = defineStore({
  id: 'pdp',
  state: () => ({
    template: null as PDPTemplate | null,
    selectedApplication: { code: undefined, group: undefined, protocolId: undefined } as SelectedApplication,
    citationsCount: 0,
    activeComponentInNavigation: null as ComponentsInNavigation['ANCHOR'] | null,
    isScrolling: false,
    renderedComponents: [] as ComponentsInNavigation[],
    isLoading: false,
    pdpPages: {} as { [key: string]: PdpPage },
    relatedProductsTemplates: {} as { [key: string]: PDPTemplate },
    hasGetclpAnySkuWithNonZeroPrice: true,
    canDisplayPriceBrick: true,
  }),
  getters: {
    showDesktopNavigation(): boolean {
      return this.renderedComponents.length > 3
    },
  },
  actions: {
    async fetchPDPTemplateForRelatedProducts(productId: string, productType: string) {
      const { data } = await this.$nuxt.$cms.api.fetchPDPTemplate({ product_id: productId, product_type: productType })
      return (this.relatedProductsTemplates[productId] = this.parseTemplate(data))
    },
    async fetchPDPPage(productId: string) {
      const { data } = await this.$nuxt.$cms.api.fetchPDPPageQuery({ product_id: productId })
      this.setPDPPagesFromResponse(data)
    },

    async fetchPDPTemplateAndPDPPage(productId: string, productType: string) {
      const response = await this.$nuxt.$cms.api.fetchPDPTemplateAndPDPPage({
        product_id: productId,
        product_type: productType,
      })

      this.setPDPPagesFromResponse(response.data)
      return this.setTemplateFromResponse(response)
    },

    parseTemplate(data: FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery) {
      let parsedTemplate: PDPTemplate
      if (data.override_template?.items?.length) {
        parsedTemplate = parseTemplateData(data.override_template)
      } else if (data.product_type_template?.items?.length) {
        parsedTemplate = parseTemplateData(data.product_type_template)
      } else {
        parsedTemplate = parseTemplateData(data.generic_template!)
      }
      return parsedTemplate
    },

    setTemplateFromResponse(response: {
      data: FetchPdpTemplateAndPdpPageByProductIdAndTypeQuery
      errors?: Error[] | undefined
    }) {
      const data = this.parseTemplate(response.data)
      this.template = data
      return data
    },

    setPDPPagesFromResponse(data: FetchPdpPageByProductIdQuery) {
      data?.all_pdp_page?.items?.forEach((item) => {
        this.pdpPages[item?.product_id!] = item!
      })
    },

    setIsLoading(value: boolean) {
      this.isLoading = value
    },

    setActiveComponentInNavigation(component: ComponentsInNavigation['ANCHOR'] | null) {
      if (!this.isScrolling) {
        // Ignore the change when page is scrolling.
        // This is needed to stop selecting navigation items/buttons while scrolling
        // when user click on a navigation item.
        this.activeComponentInNavigation = component
      }
    },

    setComponentAsRendered(component: ComponentsInNavigation) {
      let setAsRendered = true
      this.renderedComponents.forEach((comp) => {
        if (comp.DISPLAY_NAME === component.DISPLAY_NAME) {
          setAsRendered = false
        }
      })
      if (setAsRendered) {
        this.renderedComponents.push(component)
      }
    },

    switchProductListComponents(
      currName: ComponentsInNavigation['DISPLAY_NAME'],
      futureRenderedComponent: ComponentsInNavigation
    ) {
      this.renderedComponents.splice(
        this.renderedComponents.findIndex((a) => a.DISPLAY_NAME === currName),
        1,
        futureRenderedComponent
      )
    },

    setSelectedApplication(param: { code?: string; group?: string; protocolId?: string }) {
      this.selectedApplication = {
        code: param.code,
        group: param.group,
        protocolId: param.protocolId,
      }
    },
    unsetSelectedApplication() {
      this.selectedApplication = {
        code: undefined,
        group: undefined,
        protocolId: undefined,
      }
    },

    setHasGetclpAnySkuWithNonZeroPrice(value: boolean) {
      this.hasGetclpAnySkuWithNonZeroPrice = value
    },

    setCanDisplayPriceBrick(value: boolean) {
      this.canDisplayPriceBrick = value
    },
  },
})
