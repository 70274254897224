import camelCase from 'lodash/fp/camelCase'

export function parseTemplateData(targets: Record<string, any>) {
  const templateTargets = targets?.items
  if (!templateTargets?.length) {
    throw new Error('Template data parsing failed: no template targets found')
  }

  return parseTemplateTarget(templateTargets[0])
}

export type PDPTemplateComponent = {
  name: string
  components: PDPTemplateComponent[]
  args: Record<string, any>
}

type PDPColumn = { components: PDPTemplateComponent[]; width: string }

export type PDPTemplate = {
  id: string
  title: string
  rows: {
    layout: string
    columns: PDPColumn[]
  }[]
}

function parseTemplateTarget(templateTarget: Record<string, any>): PDPTemplate {
  const node = templateTarget?.templateConnection.edges.at(0).node
  const rows = node?.slots.map((row: any) => {
    const columns: Record<string, any>[][] = row.columns?.map((col: Record<string, any>) => col.column.components) ?? []

    const layout: string = row.layout
    const columnWidths = layout.split('').map((char) => Number(char))
    const columnWidthsSum = columnWidths.reduce((a, b) => a + b, 0)
    return {
      layout,
      columns: columns.map((col, i) => ({
        components: parseComponents(col),
        width: `${columnWidths[i]}/${columnWidthsSum}`,
      })),
    }
  })

  return {
    title: node?.title,
    rows,
    id: node?.system?.uid,
  }
}

function parseComponents(components: Record<string, any>[]): PDPTemplateComponent[] {
  // Remove `__typename` fields to simplify parsing
  components = components.map((c) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...component } = c
    return component
  })

  return components.map((component) => {
    const entries = Object.entries(component)
    if (entries.length !== 1) {
      throw new Error('Parsing error: encountered component with multiple keys. Only one key expected.')
    }

    const [name, value] = entries[0]
    return parseComponent(name, value)
  })
}

function parseComponent(componentName: string, component: Record<string, any>): PDPTemplateComponent {
  const { components: childComponents, ...otherArgs } = component
  const mappedComponentName = componentNameMap[componentName as keyof typeof componentNameMap]

  return {
    name: mappedComponentName,
    components: childComponents ? parseComponents(childComponents) : [],
    args: parseComponentArgs(mappedComponentName, otherArgs),
  }
}

function parseComponentArgs(componentName: string, args: Record<string, any>) {
  // Remove __typename attribute and map object keys to camelCase
  const camelCaseEntries = Object.entries(args)
    .filter(([key, _]) => key !== '__typename')
    .map(([key, value]) => {
      return [camelCase(key), value]
    })

  const mappedArgs = Object.fromEntries(camelCaseEntries)

  // These 2 components have special attribute `table_columns`.
  // Convert them to a string array so they are easier to work with.
  if (componentName === 'SupportingData' || componentName === 'ProductIncludes') {
    mappedArgs.tableColumns = mappedArgs.tableColumns.map((col: Record<string, string>) => col.name)
  }

  return mappedArgs
}

export const componentNameMap = {
  pdp_alternatives: 'Alternatives',
  pdp_antibody_reference_link: 'AntibodyReferenceLink',
  pdp_application_tab: 'ApplicationTab',
  pdp_figure_block: 'FigureBlock',
  pdp_to_purchase: 'ToPurchase',
  pdp_product_inquiry: 'ProductInquiry',
  pdp_tech_links: 'TechLinks',
  pdp_panel_builder: 'PanelBuilder',
  pdp_supporting_data: 'SupportingData',
  pdp_product_list: 'ProductList',
  pdp_conjugates: 'Conjugates',
  pdp_product_includes: 'ProductIncludes',
  pdp_related_products: 'RelatedProducts',
  pdp_product_information: 'ProductInformation',
  pdp_background: 'Background',
  pdp_database_links: 'DatabaseLinks',
  pdp_description: 'Description',
  pdp_fluorescent_properties: 'FluorescentProperties',
  pdp_kit_usage_information: 'KitUsageInformation',
  pdp_protocol: 'Protocol',
  pdp_quality_control: 'QualityControl',
  pdp_reagents_not_included: 'ReagentsNotIncluded',
  pdp_source_or_purification: 'SourceOrPurification',
  pdp_specificity_or_sensitivity: 'SpecificityOrSensitivity',
  pdp_upstream_or_downstream_pathway_image: 'UpstreamOrDownstreamPathwayImage',
  pdp_usage_information: 'UsageInformation',
  pdp_citations: 'Citations',
  pdp_legal_statement: 'LegalStatement',
  pdp_inpage_navigation: 'InPageNavigation',
} as const
