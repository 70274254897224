import https from 'https'

export default function ({ $axios, $config }, inject) {
  let baseURL = `/api/paymetric`

  if (process.server) {
    baseURL = `https://${$config.nuxtHost}:${$config.nuxtPort}${baseURL}` // loopback
  }

  // Create a custom axios instance
  const payment = $axios.create({
    baseURL,
    httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }),
  })

  // Inject to context as $payment
  inject('payment', payment)
}
