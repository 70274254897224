import { BLOG_URL } from '@/common/static/search'
import { decodeHTMLEntities } from '@/common/utils/characters'
import { H1_CRAWLER_FIELD_NAME } from '@/common/constants/cms'
import { ElasticFieldsInterface } from '@/types/search/elasticTypes'

export const getResourceHeading = (fields: ElasticFieldsInterface) => {
  const headings = fields?.headings?.raw
  const pageTitle = fields?.[H1_CRAWLER_FIELD_NAME]?.raw

  // Support for h1 field in Resources engine
  if (pageTitle) {
    return decodeHTMLEntities(pageTitle)
  }

  // if h1 is not created, return the headings field
  if (!headings) return

  // Take the second element if we have a BLOG or CAREERS URL
  if (fields.url?.raw.includes(BLOG_URL)) {
    return decodeHTMLEntities(headings[1])
  }

  return decodeHTMLEntities(headings[0])
}
